// app/javascript/packs/custom.js

//console.log("custom js file loaded")

$(function() {

    $(".grid-btn").on('click', function () {
        $(".section-grid-list").removeClass("section-grid-list-vertical");
    });

    $(".list-btn").on('click', function () {
        $(".section-grid-list").removeClass("section-grid-list-vertical");
        $(".section-grid-list").addClass("section-grid-list-vertical");
    });


    $(".tab-link").on('click', function () {
        var tab = $(this).data("tab");
        $(".tab-content").hide();
        $("#" + tab).show();
        $(".tab-link").removeClass("btn-secondary");
        $(".tab-link").addClass("btn-outline-secondary");
        $(this).removeClass("btn-outline-secondary");
        $(this).addClass("btn-secondary");
    });

    $("#hamburger").on('click', function (e) {
        e.preventDefault();

        var x = document.getElementById("myLinks");
        if (x.style.display === "block") {
        x.style.display = "none";
        } else {
        x.style.display = "block";
        }
    });

    var options = {
        valueNames: ['name', 'company-type', 'state'],
        page: 10,
        pagination: true
    };
      
    var companyList = new List('companies', options);

    var options = {
        url: function(phrase) {
            return "/company/all_companies";
        },
    
        getValue: "name",

        list: {

            onSelectItemEvent: function() {
                var value = $("#company_search").getSelectedItemData().id;
    
                console.log(value);

                $("#company_search_id").val(value);
            }
        }
    };

    if( $("#company_search").length > 0 ) {
        $("#company_search").easyAutocomplete(options);
    }

    $('#mailing_country_id').on('change', function(){
        var value = $(this).val()
        console.log(value);
        $.ajax({
           type: 'POST',
           url: '/api/mailing_states',
           data: {id: value},
            success: function (data) {
                console.log(data);
             $('#mailing_state_options').html(data)
           }
        })
    })

    $('#work_country_id').on('change', function(){
        var value = $(this).val()
        console.log(value);
        $.ajax({
           type: 'POST',
           url: '/api/work_states',
           data: {id: value},
            success: function (data) {
                console.log(data);
             $('#work_state_options').html(data)
           }
        })
    })

    $('#country_id').on('change', function(){
        var value = $(this).val()
        console.log(value);
        $.ajax({
           type: 'POST',
           url: '/api/company_states',
           data: {id: value},
            success: function (data) {
                console.log(data);
             $('#state_id_options').html(data)
           }
        })
    })

    $('#country').on('change', function(){
        var value = $(this).val()
        console.log(value);
        $.ajax({
           type: 'POST',
           url: '/api/company_states',
           data: {id: value},
            success: function (data) {
                console.log(data);
             $('#company_state_options').html(data)
           }
        })
    })

    $('#user_properties_issuing_country').on('change', function(){
        var value = $(this).val()
        console.log(value);
        $.ajax({
           type: 'POST',
           url: '/api/company_states',
           data: {id: value},
            success: function (data) {
                console.log(data);
             $('#user_properties_issuing_state').html(data)
           }
        })
    })

    
    
});

